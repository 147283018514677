import type ProductLicensesFormValues from 'src/models/License/ProductLicenseFormValues';
import type ProductLicensesRequestValues from 'src/models/License/ProductLicensesRequestValues';

export default function toProductLicenseAddRequest(
  data: ProductLicensesFormValues
): ProductLicensesRequestValues {
  const {
    accountId,
    barrierCount,
    bollardCount,
    brainCount,
    chainCount,
    parkingPlaceCount,
    lprCameraCount,
    gateCount,
  } = data;

  return {
    account_id: accountId,
    barrier_count: barrierCount,
    lpr_camera_count: lprCameraCount,
    brain_count: brainCount,
    chain_count: chainCount,
    gate_count: gateCount,
    bollard_count: bollardCount,
    parking_place_count: parkingPlaceCount,
  };
}
