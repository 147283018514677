import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Container from '../../components/Auth/Container';
import Color from '../../constants/Shared/Color';

export default function GeneralTerms() {
  return (
    <Container removeText fixedsvg='true'>
      <StyledDiv>
        <StyledH2>Software as a Service (SaaS)</StyledH2>
        <StyledH2>Terms and Conditions</StyledH2>
        <StyledText>
          Please read these terms and conditions carefully, as they set out our
          and your legal rights and obligations in relation to our Parklio
          platform and services.{' '}
        </StyledText>
        <StyledH3>1. Definitions and interpretation</StyledH3>
        <StyledParagraph>1.1 In the Agreement:</StyledParagraph>
        <StyledP>
          <b>&#34;Affiliate&#34;</b> means an entity that Controls, is
          Controlled by, or is under common Control with the relevant entity;
        </StyledP>
        <StyledP>
          <b>&#34;Agreement&#34;</b> means the agreement between the Provider
          and the Customer for the provision of the Platform as a service,
          incorporating these terms and conditions (including the Schedules) and
          the Statement of Services, to the Agreement from time to time; and any
          amendments to the Agreement from time to time;
        </StyledP>
        <StyledP>
          <b>&#34;Business Day&#34;</b> means any week day, other than a bank or
          public holiday in England;
        </StyledP>
        <StyledP>
          <b>&#34;Business Hours&#34;</b> means between 09:00 and 17:00 CET on a
          Business Day;
        </StyledP>
        <StyledP>
          <b>&#34;Charges&#34;</b> means the amounts payable by the Customer to
          the Provider under or in relation to the Agreement (as set out in
          Schedule 2);
        </StyledP>
        <StyledP>
          <b>&#34;Control&#34;</b> means the legal power to control (directly or
          indirectly) the management of an entity (and &#34;Controlled&#34; will
          be construed accordingly);
        </StyledP>
        <StyledP>
          <b>&#34;Customer&#34;</b> means the customer specified in the
          Statement of Services;
        </StyledP>
        <StyledP>
          <b>&#34;Customer Confidential Information&#34;</b> means
        </StyledP>
        <StyledUl>
          <StyledLi>
            any information disclosed (whether disclosed in writing, orally or
            otherwise) by the Customer to the Provider that is marked as
            &#34;confidential&#34;, described as &#34;confidential&#34; or
            should have been understood by the Provider at the time of
            disclosure to be confidential;
          </StyledLi>
          <StyledLi>
            the financial terms and conditions of the Agreement; and
          </StyledLi>
          <StyledLi>the Customer Materials;</StyledLi>
        </StyledUl>
        <StyledP>
          <b>&#34;Customer Materials&#34;</b> all works and materials:
        </StyledP>
        <StyledUl>
          <StyledLi>
            uploaded to, stored on, processed using or transmitted via the
            Platform by or on behalf of the Customer or by any person or
            application or automated system using the Customer&#39;s account;
            and
          </StyledLi>
          <StyledLi>
            otherwise provided by the Customer to the Provider in connection
            with the Agreement;
          </StyledLi>
        </StyledUl>
        <StyledP>
          <b>&#34;Defect&#34;</b> means a defect, error or bug having a
          materially adverse effect on the appearance, operation or
          functionality of the Platform, but excluding any defect, error or bug
          caused by or arising as a result of:
        </StyledP>
        <StyledUl>
          <StyledLi>
            an act or omission of the Customer, or an act or omission of one of
            the Customer&#39;s employees, officers, agents, suppliers or
            sub-contractors; or
          </StyledLi>
          <StyledLi>
            an incompatibility between the Platform and any other system,
            application, program or software not specified as compatible in the
            Statement of Services;
          </StyledLi>
        </StyledUl>
        <StyledP>
          <b>&#34;Documentation&#34;</b> means the documentation produced by the
          Provider and made available on the Platform to the Customer specifying
          how the Platform should be used;
        </StyledP>
        <StyledP>
          <b>&#34;Effective Date&#34;</b> means the date that the Agreement
          comes into force as specified in Clause 2;
        </StyledP>
        <StyledP>
          <b>&#34;Force Majeure Event&#34;</b> means an event, or a series of
          related events, that is outside the reasonable control of the party
          affected (including failures of or problems with the internet or a
          part of the internet, hacker attacks, virus or other malicious
          software attacks or infections, power failures, industrial disputes
          affecting any third party, changes to the law, disasters, explosions,
          fires, floods, riots, terrorist attacks and wars);
        </StyledP>
        <StyledP>
          <b>&#34;Intellectual Property Rights&#34;</b> means all intellectual
          property rights wherever in the world, whether registered or
          unregistered, including any application or right of application for
          such rights (and the &#34;intellectual property rights&#34; referred
          to above include copyright and related rights, database rights,
          confidential information, trade secrets, know-how, business names,
          trade names, trademarks, service marks, passing off rights, unfair
          competition rights, patents, petty patents, utility models,
          semi-conductor topography rights and rights in designs);
        </StyledP>
        <StyledP>
          <b>&#34;Minimum Term&#34;</b> means the period specified as such in
          the Statement of Services;
        </StyledP>
        <StyledP>
          <b>&#34;Permitted Purpose&#34;</b> means the use of the Platform by
          the Customer to manage its parking lot;
        </StyledP>
        <StyledP>
          <b>&#34;Platform&#34;</b> means the software platform known as Parklio
          PMS (Parking Management System) that is owned and operated by the
          Provider, and that will be made available to the Customer as a service
          via the internet under the Agreement;
        </StyledP>
        <StyledP>
          <b>&#34;Provider&#34;</b> means Amplifico Limited, a company
          incorporated in Croatia (registration number 060323069) having its
          registered office at Hercegovacka 78, 21000 Split, Croatia;
        </StyledP>
        <StyledP>
          <b>&#34;Schedule&#34;</b> means a schedule attached to the Agreement;
        </StyledP>
        <StyledP>
          <b>&#34;Services&#34;</b> means all the services provided or to be
          provided by the Provider to the Customer under the Agreement,
          including the Support Services;
        </StyledP>
        <StyledP>
          <b>&#34;Statement of Services&#34;</b> means the document made
          available by the Provider to the Customer during the order process
          that specifies the identity of the Customer, and other matters
          relating to the Agreement;
        </StyledP>
        <StyledP>
          <b>&#34;Support Services&#34;</b> means support and maintenance
          services provided or to be provided by the Provider to the Customer in
          accordance with Schedule 1;
        </StyledP>
        <StyledP>
          <b>&#34;Term&#34;</b> means the term of the Agreement; and
        </StyledP>
        <StyledP>
          <b>&#34;Upgrades&#34;</b> means new versions of, and updates to, the
          Platform, whether for the purpose of fixing an error, bug or other
          issue in the Platform or enhancing the functionality of the Platform.
        </StyledP>
        <StyledParagraph>
          1.2 In the Agreement, a reference to a statute or statutory provision
          includes a reference to:
        </StyledParagraph>
        <StyledUl>
          <StyledLi>
            that statute or statutory provision as modified, consolidated and/or
            reenacted from time to time; and
          </StyledLi>
          <StyledLi>
            any subordinate legislation made under that statute or statutory
            provision.
          </StyledLi>
        </StyledUl>
        <StyledParagraph>
          1.3 The Clause headings do not affect the interpretation of the
          Agreement.
        </StyledParagraph>
        <StyledParagraph>
          1.4 The ejusdem generis rule is not intended to be used in the
          interpretation of the Agreement.
        </StyledParagraph>
        <StyledH3>2. Agreement and Term</StyledH3>
        <StyledParagraph>
          2.1 The advertising of the Platform and the Services on the
          Provider&#39;s website constitutes an &#34;invitation to treat&#34;;
          and the Customer&#39;s order for the Platform and the Services
          constitutes a contractual offer. No contract will come into force
          between the Provider and the Customer unless and until the Provider
          accepts the Customer&#39;s order in accordance with the procedure
          detailed in this Clause 2.
        </StyledParagraph>
        <StyledParagraph>
          2.2 In order to enter into the Agreement, the Customer must take the
          following steps:
        </StyledParagraph>
        <StyledUl>
          <StyledNestedLi>
            the Customer must place its order with the Provider;
          </StyledNestedLi>
          <StyledNestedLi>
            the Provider will then draw up a sales order which gives details of
            the services to be provided and the amounts payable by the Customer;
          </StyledNestedLi>
          <StyledNestedLi>
            the Customer will then confirm its acceptance of the sales order
            including its terms and conditions;
          </StyledNestedLi>
          <StyledNestedLi>
            once the payment has been received by the Provider the Customer will
            be given access to the Platform (at which point the Agreement will
            come into force).
          </StyledNestedLi>
        </StyledUl>
        <StyledParagraph>
          2.3 Once in force, the Agreement will continue in force for the
          Minimum Term and indefinitely thereafter, unless terminated earlier in
          accordance with Clause 13.
        </StyledParagraph>
        <StyledH3>3. The Platform</StyledH3>
        <StyledParagraph>
          3.1 The Provider will make available the Platform to the Customer by
          setting up an account for the Customer on the Platform, and providing
          to the Customer login details for that account as soon as practicable
          following the Effective Date.
        </StyledParagraph>
        <StyledParagraph>
          3.2 Subject to the limitations set out in Clause 3.3 and the
          prohibitions set out in Clause 3.4, the Provider hereby grants to the
          Customer a non-exclusive licence to use the Platform for the Permitted
          Purpose via any standard web browser in accordance with the
          Documentation during the Term.
        </StyledParagraph>
        <StyledParagraph>
          3.3 The licence granted by the Provider to the Customer under Clause
          3.2 is subject to the following limitations:
        </StyledParagraph>
        <StyledUl>
          <StyledLi>
            the Platform may only be used by the named users identified in the
            Statement of Services, providing that the Customer may change, add
            or remove a designated named user in accordance with the procedure
            set out therein;
          </StyledLi>
          <StyledLi>
            the Platform must not be used at any point in time by more than the
            number of concurrent users specified in the Statement of Services,
            providing that the Customer may add or remove concurrent user
            licences in accordance with the procedure set out therein;
          </StyledLi>
          <StyledLi>
            the Platform may only be used by the employees, agents and
            subcontractors of the Customer and:
            <StyledUl>
              <StyledNestedLi>
                where the Customer is a company, the Customer&#39;s officers;
              </StyledNestedLi>
              <StyledNestedLi>
                where the Customer is a partnership, the Customer&#39;s
                partners; and
              </StyledNestedLi>
              <StyledNestedLi>
                where the Customer is a limited liability partnership, the
                Customer&#39;s members;
              </StyledNestedLi>
            </StyledUl>
          </StyledLi>
          <StyledLi>
            the Customer must comply at all times with the terms of the
            acceptable use policy set out in Schedule 3, and must ensure that
            all users of the Platform agree to and comply with the terms of that
            acceptable use policy;
          </StyledLi>
        </StyledUl>
        <StyledParagraph>
          3.4 Except to the extent mandated by applicable law or expressly
          permitted in the Agreement, the licence granted by the Provider to the
          Customer under this Clause 3 is subject to the following prohibitions:
        </StyledParagraph>
        <StyledUl>
          <StyledLi>
            the Customer must not sub-license its right to access and use the
            Platform or allow any unauthorised person to access or use the
            Platform;
          </StyledLi>
          <StyledLi>
            the Customer must not frame or otherwise re-publish or re-distribute
            the Platform; and
          </StyledLi>
          <StyledLi>
            the Customer must not alter or adapt or edit the Platform save as
            expressly permitted by the Documentation.
          </StyledLi>
        </StyledUl>
        <StyledParagraph>
          3.5 For the avoidance of doubt, the Customer has no right to access
          the object code or source code of the Platform, either during or after
          the Term.
        </StyledParagraph>
        <StyledParagraph>
          3.6 All Intellectual Property Rights in the Platform shall, as between
          the parties, be the exclusive property of the Provider
        </StyledParagraph>
        <StyledParagraph>
          3.7 The Customer shall use all reasonable endeavours to ensure that no
          unauthorised person will or could access the Platform using the
          Customer&#39;s account.
        </StyledParagraph>
        <StyledParagraph>
          3.8 The Customer must not use the Platform in any way that causes, or
          may cause, damage to the Platform or impairment of the availability or
          accessibility of the Platform, or any of the areas of, or services on,
          the Platform.
        </StyledParagraph>
        <StyledParagraph>
          3.9 The Customer must not use the Platform:
        </StyledParagraph>
        <StyledUl>
          <StyledLi>
            in any way that is unlawful, illegal, fraudulent or harmful; or
          </StyledLi>
          <StyledLi>
            in connection with any unlawful, illegal, fraudulent or harmful
            purpose or activity.
          </StyledLi>
        </StyledUl>
        <StyledH3>4. Support Services and Upgrades</StyledH3>
        <StyledParagraph>
          4.1 During the Term the Provider will provide the Support Services to
          the Customer, and may apply Upgrades to the Platform, in accordance
          with the service level agreement set out in Schedule 1.
        </StyledParagraph>
        <StyledParagraph>
          4.2 The Provider may sub-contract the provision of any of the Support
          Services without obtaining the consent of the Customer
        </StyledParagraph>
        <StyledH3>5. Customer Materials</StyledH3>
        <StyledParagraph>
          5.1 The Customer grants to the Provider during the Term a
          non-exclusive licence to store, copy and otherwise use the Customer
          Materials on the Platform for the purposes of operating the Platform,
          providing the Services, fulfilling its other obligations under the
          Agreement, and exercising its rights under the Agreement.
        </StyledParagraph>
        <StyledParagraph>
          5.2 Subject to Clause 5.1, all Intellectual Property Rights in the
          Customer Materials will remain, as between the parties, the property
          of the Customer.
        </StyledParagraph>
        <StyledParagraph>
          5.3 The Customer warrants and represents to the Provider that the
          Customer Materials, and their use by the Provider in accordance with
          the terms of the Agreement, will not:
        </StyledParagraph>
        <StyledUl>
          <StyledLi>
            breach any laws, statutes, regulations or legally-binding codes;
          </StyledLi>
          <StyledLi>
            infringe any person&#39;s Intellectual Property Rights or other
            legal rights; or
          </StyledLi>
          <StyledLi>
            give rise to any cause of action against the Provider or the
            Customer or any third party,
          </StyledLi>
        </StyledUl>
        <StyledParagraph>
          in each case in Croatia and under Croatian law.
        </StyledParagraph>
        <StyledParagraph>
          5.4 Where the Provider reasonably suspects that there has been a
          breach by the Customer of the provisions of this Clause 5, the
          Provider may:
        </StyledParagraph>
        <StyledUl>
          <StyledLi>
            delete or amend the relevant Customer Materials; and/or
          </StyledLi>
          <StyledLi>
            suspend any or all of the Services and/or the Customer’s access to
            the Platform while it investigates the matter
          </StyledLi>
        </StyledUl>
        <StyledParagraph>
          5.5 Any breach by the Customer of this Clause 5 will be deemed to be a
          material breach of the Agreement for the purposes of Clause 13.
        </StyledParagraph>
        <StyledParagraph>
          5.6 The Provider shall ensure that the Customer Materials stored and
          processed by the Platform are stored separately from, and are not
          co-mingled with, the materials of other customers of the Provider.
        </StyledParagraph>
        <StyledH3>6. Platform Trial</StyledH3>
        <StyledText>
          The Provider may, in special circumstances, set up a Platform Trial.
          The Customer may access the Platform Trial for an agreed period,
          during which time all of the provisions of this Agreement shall apply,
          save as follows:
        </StyledText>
        <ul>
          <StyledLi>
            the Customer shall have no obligation to pay any Charges in respect
            of the Platform Trial;
          </StyledLi>
        </ul>
        <StyledH3>7. Charges</StyledH3>
        <StyledParagraph>
          7.1 The Provider will issue invoices for the Charges to the Customer
          in accordance with the provisions of Schedule 2.
        </StyledParagraph>
        <StyledParagraph>
          7.2 All Charges stated in or in relation to the Agreement are stated
          exclusive of VAT, unless the context requires otherwise. VAT will be
          payable by the Customer to the Provider in addition to the principal
          amounts. All Charges must be paid by direct debit.
        </StyledParagraph>
        <StyledParagraph>
          7.3 If the Customer does not pay any amount properly due to the
          Provider under or in connection with the Agreement, the Provider may:
        </StyledParagraph>
        <StyledUl>
          <StyledLi>
            charge the Customer interest on the overdue amount at the rate of 5%
            per year above the base rate of NatWest Bank Plc from time to time
            (which interest will accrue daily and be compounded quarterly); or
          </StyledLi>
          <StyledLi>
            claim interest and statutory compensation from the Customer
          </StyledLi>
        </StyledUl>
        <StyledParagraph>
          7.4 The Provider may vary the Charges on and from any anniversary of
          the Effective Date by giving to the Customer not less than 30
          days&#39; written notice of the variation.{' '}
        </StyledParagraph>
        <StyledParagraph>
          7.5 The Provider may suspend access to the Platform and the provision
          of the Services if any amounts due to be paid by the Customer to the
          Provider under the Agreement are overdue by more than 20 days.
        </StyledParagraph>
        <StyledH3>Warranties</StyledH3>
        <StyledParagraph>
          8.1 The Customer warrants and represents to the Provider that it has
          the legal right and authority to enter into and perform its
          obligations under the Agreement.
        </StyledParagraph>
        <StyledParagraph>
          8.2 The Provider warrants and represents to the Customer:
        </StyledParagraph>
        <StyledUl>
          <StyledLi>
            that it has the legal right and authority to enter into and perform
            its obligations under the Agreement;
          </StyledLi>
          <StyledLi>
            that it will perform its obligations under the Agreement with
            reasonable care and skill;
          </StyledLi>
          <StyledLi>
            that the Platform will operate without Defects and will perform
            substantially in accordance with the Documentation (subject to any
            Upgrades);
          </StyledLi>
          <StyledLi>
            that the Platform will be hosted in accordance with the requirements
            set out in the Statement of Services, and will be available to the
            Customer in accordance with the uptime commitments given in Schedule
            1;
          </StyledLi>
          <StyledLi>
            the Platform (excluding for the avoidance of doubt the Customer
            Materials) will not:
          </StyledLi>
          <StyledUl>
            <StyledNestedLi>
              breach any laws, statutes, regulations or legally-binding codes;
            </StyledNestedLi>
            <StyledNestedLi>
              infringe any person&#39;s Intellectual Property Rights or other
              legal rights; or
            </StyledNestedLi>
            <StyledNestedLi>
              give rise to any cause of action against the Provider or the
              Customer or any third party, in each case in England and Wales and
              under English law; and
            </StyledNestedLi>
          </StyledUl>
          <StyledLi>
            the Platform is and will remain free from viruses and other
            malicious software programs.
          </StyledLi>
        </StyledUl>
        <StyledParagraph>8.3 The Customer acknowledges that:</StyledParagraph>
        <StyledUl>
          <StyledLi>
            complex software is never wholly free from defects, errors and bugs,
            and the Provider gives no warranty or representation that the
            Platform will be wholly free from such defects, errors and bugs;
          </StyledLi>
          <StyledLi>
            the Provider does not warrant or represent that the Platform will be
            compatible with any application, program or software not
            specifically identified as compatible in the Statement of Services;
            and
          </StyledLi>
          <StyledLi>
            the Provider will not and does not purport to provide any legal,
            taxation or accountancy advice under the Agreement or in relation to
            the Platform and (except to the extent expressly provided otherwise)
            the Provider does not warrant or represent that the Platform will
            not give rise to any civil or criminal legal liability on the part
            of the Customer or any other person.
          </StyledLi>
        </StyledUl>
        <StyledParagraph>
          All of the parties&#39; warranties and representations in respect of
          the subject matter of the Agreement are expressly set out in the terms
          of the Agreement. To the maximum extent permitted by applicable law,
          no other warranties or representations concerning the subject matter
          of the Agreement will be implied into the Agreement.
        </StyledParagraph>
        <StyledH3>9. Indemnities</StyledH3>
        <StyledParagraph>
          9.1 The Customer will indemnify and will keep indemnified the Provider
          against all liabilities, damages, losses, costs and expenses
          (including legal expenses and amounts paid upon legal advice in
          settlement of any disputes) suffered or incurred by the Provider and
          arising as a result of any breach by the Customer of Clause 5.3.
        </StyledParagraph>
        <StyledParagraph>
          9.2 The Provider will indemnify and will keep indemnified the Customer
          against all liabilities, damages, losses, costs and expenses
          (including legal expenses and amounts paid upon legal advice in
          settlement of any disputes) suffered or incurred by the Customer and
          arising as a result of any breach by the Provider of Clause 8.2(e).
        </StyledParagraph>
        <StyledH3>10. Limitations and exclusions of liability</StyledH3>
        <StyledParagraph>10.1 Nothing in the Agreement will:</StyledParagraph>
        <StyledUl>
          <StyledLi>
            limit or exclude the liability of a party for death or personal
            injury resulting from negligence;
          </StyledLi>
          <StyledLi>
            limit or exclude the liability of a party for fraud or fraudulent
            misrepresentation by that party;
          </StyledLi>
          <StyledLi>
            limit any liability of a party in any way that is not permitted
            under applicable law; or
          </StyledLi>
          <StyledLi>
            exclude any liability of a party that may not be excluded under
            applicable law.
          </StyledLi>
        </StyledUl>
        <StyledParagraph>
          10.2 The limitations and exclusions of liability set out in this
          Clause 10 and elsewhere in the Agreement:
        </StyledParagraph>
        <StyledUl>
          <StyledLi>are subject to Clause 10.1;</StyledLi>
          <StyledLi>
            govern all liabilities arising under the Agreement or any collateral
            contract or in relation to the subject matter of the Agreement or
            any collateral contract, including liabilities arising in contract,
            in tort (including negligence) and for breach of statutory duty; and
          </StyledLi>
        </StyledUl>
        <StyledParagraph>
          10.3 The Provider will not be liable in respect of any loss of
          profits, income, revenue, use, production or anticipated savings.
        </StyledParagraph>
        <StyledParagraph>
          10.4 The Provider will not be liable for any loss of business,
          contracts or commercial opportunities.
        </StyledParagraph>
        <StyledParagraph>
          10.5 The Provider will not be liable for any loss of or damage to
          goodwill or reputation
        </StyledParagraph>
        <StyledParagraph>
          10.6 The Provider will not be liable in respect of any loss or
          corruption of any data, database or software.
        </StyledParagraph>
        <StyledParagraph>
          10.7 The Provider will not be liable in respect of any special,
          indirect or consequential loss or damage.
        </StyledParagraph>
        <StyledParagraph>
          10.8 Neither party will be liable for any losses arising out of a
          Force Majeure Event.
        </StyledParagraph>
        <StyledParagraph>
          10.9 The Provider’s liability in relation to any event or series of
          related events will not exceed the total amount paid and payable by
          the Customer to the Provider under the Agreement during the 12 month
          period immediately preceding the event or events giving rise to the
          claim.
        </StyledParagraph>
        <StyledH3>11. Data protection</StyledH3>
        <StyledParagraph>
          11.1 The Customer warrants that it has the legal right to disclose all
          Personal Data that it does in fact disclose to the Provider under or
          in connection with the Agreement.
        </StyledParagraph>
        <StyledParagraph>11.2 The Provider warrants that: </StyledParagraph>
        <StyledUl>
          <StyledLi>
            it will act only on instructions from the Customer in relation to
            the processing of any Personal Data performed by the Provider on
            behalf of the Customer; and
          </StyledLi>
          <StyledLi>
            it has in place appropriate security measures (both technical and
            organisational) against unlawful or unauthorised processing of
            Personal Data and against loss or corruption of Personal Data
            processed by the Provider on behalf of the Customer
          </StyledLi>
        </StyledUl>
        <StyledH3>12. Confidentiality</StyledH3>
        <StyledParagraph>12.1 The Provider will:</StyledParagraph>
        <StyledUl>
          <StyledLi>
            keep confidential and not disclose the Customer Confidential
            Information to any person save as expressly permitted by this Clause
            12;
          </StyledLi>
          <StyledLi>
            protect the Customer Confidential Information against unauthorised
            disclosure by using the same degree of care as it takes to preserve
            and safeguard its own confidential information of a similar nature,
            being at least a reasonable degree of care; and
          </StyledLi>
        </StyledUl>
        <StyledParagraph>
          12.2 Customer Confidential Information may be disclosed by the
          Provider to its officers, employees, agents, insurers and professional
          advisers, provided that the recipient is bound in writing to maintain
          the confidentiality of the Customer Confidential Information
          disclosed.
        </StyledParagraph>
        <StyledParagraph>
          12.3 The obligations set out in this Clause 12 shall not apply to:
        </StyledParagraph>
        <StyledUl>
          <StyledLi>
            Customer Confidential Information that is publicly known (other than
            through a breach of an obligation of confidence);
          </StyledLi>
          <StyledLi>
            Customer Confidential Information that is in possession of the
            Provider prior to disclosure by the Customer;
          </StyledLi>
          <StyledLi>
            Customer Confidential Information that is received by the Provider
            from an independent third party who has a right to disclose the
            relevant Confidential Information; or
          </StyledLi>
          <StyledLi>
            Customer Confidential Information that is required to be disclosed
            by law, or by a governmental authority, stock exchange or regulatory
            body, provided that the Provider must where permitted by law give to
            the Customer prompt written notice of the disclosure requirement.
          </StyledLi>
        </StyledUl>
        <StyledH3>13. Termination</StyledH3>
        <StyledParagraph>
          13.1 Either party may terminate the Agreement immediately by giving
          written notice to the other party if the other party:
        </StyledParagraph>
        <StyledUl>
          <StyledLi>
            commits any material breach of any term of the Agreement, and:
          </StyledLi>
          <StyledUl>
            <StyledNestedLi>the breach is not remediable; or</StyledNestedLi>
            <StyledNestedLi>
              the breach is remediable, but the other party fails to remedy the
              breach within 30 days of receipt of a written notice requiring it
              to do so; or
            </StyledNestedLi>
          </StyledUl>
          <StyledLi>
            persistently breaches the terms of the Agreement (irrespective of
            whether such breaches collectively constitute a material breach).
          </StyledLi>
        </StyledUl>
        <StyledParagraph>
          13.2 Either party may terminate the Agreement immediately by giving
          written notice to the other party if:
        </StyledParagraph>
        <StyledUl>
          <StyledLi>the other party:</StyledLi>
          <StyledUl>
            <StyledNestedLi>is dissolved;</StyledNestedLi>
            <StyledNestedLi>
              ceases to conduct all (or substantially all) of its business;
            </StyledNestedLi>
            <StyledNestedLi>
              is or becomes unable to pay its debts as they fall due;
            </StyledNestedLi>
            <StyledNestedLi>
              is or becomes insolvent or is declared insolvent; or
            </StyledNestedLi>
            <StyledNestedLi>
              convenes a meeting or makes or proposes to make any arrangement or
              composition with its creditors;
            </StyledNestedLi>
          </StyledUl>
          <StyledLi>
            an administrator, administrative receiver, liquidator, receiver,
            trustee, manager or similar is appointed over any of the assets of
            the other party;
          </StyledLi>
          <StyledLi>
            ) an order is made for the winding up of the other party, or the
            other party passes a resolution for its winding up (other than for
            the purpose of a solvent company reorganisation where the resulting
            entity will assume all the obligations of the other party under the
            Agreement); or
          </StyledLi>
          <StyledLi>
            (where that other party is an individual) that other party dies, or
            as a result of illness or incapacity becomes incapable of managing
            his or her own affairs, or is the subject of a bankruptcy petition
            or order.
          </StyledLi>
        </StyledUl>
        <StyledParagraph>
          13.3 Either party may terminate the Agreement by giving at least 30
          days&#39; written notice of termination to the other party, expiring
          at any time after the end of the Minimum Term.
        </StyledParagraph>
        <StyledParagraph>
          13.4 If the Provider stops or makes a good faith decision to stop
          operating the Platform generally, then the Provider may terminate the
          Agreement by giving at least 30 days&#39; written notice of
          termination to the Customer.
        </StyledParagraph>
        <StyledParagraph>
          13.5 The Provider may terminate the Agreement immediately by giving
          written notice of termination to the Customer where the Customer fails
          to pay to the Provider any amount due to be paid under the Agreement
          by the due date.
        </StyledParagraph>
        <StyledH3>14. Effects of termination</StyledH3>
        <StyledParagraph>
          14.1 Upon termination of the Agreement, all the provisions of the
          Agreement will cease to have effect, save that the following
          provisions of the Agreement will survive and continue to have effect
          (in accordance with their terms or otherwise indefinitely): Clauses 1,
          7.5, 9, 10, 12, 14 and 17.
        </StyledParagraph>
        <StyledParagraph>
          14.2 Termination of the Agreement will not affect either party&#39;s
          accrued liabilities and rights as at the date of termination.
        </StyledParagraph>
        <StyledParagraph>
          14.3 Subject to Clause 14.4, within 30 days following the termination
          of the Agreement, the Provider will:
        </StyledParagraph>
        <StyledUl>
          <StyledLi>
            irrevocably delete from the Platform all Customer Confidential
            Information; and
          </StyledLi>
          <StyledLi>
            irrevocably delete from its other computer systems all Customer
            Confidential Information, and return to the Customer or dispose of
            as the Customer may instruct all documents and materials containing
            Customer Confidential Information.
          </StyledLi>
        </StyledUl>
        <StyledParagraph>
          14.4 The Provider may retain any document (including any electronic
          document) containing the Customer Confidential Information after the
          termination of the Agreement if:
        </StyledParagraph>
        <StyledUl>
          <StyledLi>
            the Provider is obliged to retain such document by any law or
            regulation or other rule enforceable against the Provider; or
          </StyledLi>
          <StyledLi>
            the document in question is a letter, fax, email, order
            confirmation, invoice, receipt or similar document addressed to the
            Provider.
          </StyledLi>
        </StyledUl>
        <StyledH3>15. Notices</StyledH3>
        <StyledParagraph>
          15.1 Any notice given under the Agreement must be in writing (whether
          or not described as &#34;written notice&#34; in the Agreement) and
          must be delivered personally, sent by recorded signed-for post, or
          sent by email, for the attention of the relevant person, and to the
          relevant address or fax number or email address given below (or as
          notified by one party to the other in accordance with this Clause).
        </StyledParagraph>
        <StyledParagraph>
          The Provider: Amplifico Limited, Hercegovacka 78, 21000 Split,
          Croatia, e-mail info@parklio.com
        </StyledParagraph>
        <StyledParagraph>
          The Customer: The addressee, address, email and fax set out in the
          Statement of Services.
        </StyledParagraph>
        <StyledParagraph>
          15.2 A notice will be deemed to have been received at the relevant
          time set out below (or where such time is not within Business Hours,
          when Business Hours next begin after the relevant time set out below):
        </StyledParagraph>
        <StyledUl>
          <StyledLi>
            where the notice is delivered personally, at the time of delivery;
          </StyledLi>
          <StyledLi>
            where the notice is sent by recorded signed-for post, 48 hours after
            posting; and
          </StyledLi>
          <StyledLi>
            where the notice is sent by fax or email, at the time of the
            transmission (providing the sending party retains written evidence
            of the transmission).
          </StyledLi>
        </StyledUl>
        <StyledH3>16. Force Majeure Event</StyledH3>
        <StyledParagraph>
          16.1 Where a Force Majeure Event gives rise to a failure or delay in
          either party performing its obligations under the Agreement (other
          than obligations to make payment), those obligations will be suspended
          for the duration of the Force Majeure Event.
        </StyledParagraph>
        <StyledParagraph>
          16.2 A party who becomes aware of a Force Majeure Event which gives
          rise to, or which is likely to give rise to, any failure or delay in
          performing its obligations under the Agreement, will:
        </StyledParagraph>
        <StyledUl>
          <StyledLi>forthwith notify the other; and</StyledLi>
          <StyledLi>
            will inform the other of the period for which it is estimated that
            such failure or delay will continue.
          </StyledLi>
        </StyledUl>
        <StyledParagraph>
          16.3 The affected party will take reasonable steps to mitigate the
          effects of the Force Majeure Event.
        </StyledParagraph>
        <StyledH3>17. General</StyledH3>
        <StyledParagraph>
          17.1 No breach of any provision of the Agreement will be waived except
          with the express written consent of the party not in breach.
        </StyledParagraph>
        <StyledParagraph>
          17.2 If a Clause of the Agreement is determined by any court or other
          competent authority to be unlawful and/or unenforceable, the other
          Clauses of the Agreement will continue in effect. If any unlawful
          and/or unenforceable Clause would be lawful or enforceable if part of
          it were deleted, that part will be deemed to be deleted, and the rest
          of the Clause will continue in effect (unless that would contradict
          the clear intention of the parties, in which case the entirety of the
          relevant Clause will be deemed to be deleted).
        </StyledParagraph>
        <StyledParagraph>
          17.3 The Agreement may not be varied except by a written document
          signed by or on behalf of each of the parties.
        </StyledParagraph>
        <StyledParagraph>
          17.4 Each party hereby agrees that the other party may freely assign
          any or all of its contractual rights and/or obligations under the
          Agreement to any Affiliate of the assigning party or any successor to
          all or a substantial part of the business of the assigning party from
          time to time. Save as expressly provided in this Clause or elsewhere
          in the Agreement, neither party may without the prior written consent
          of the other party assign, transfer, charge, license or otherwise
          dispose of or deal in the Agreement or any contractual rights or
          obligations under the Agreement.
        </StyledParagraph>
        <StyledParagraph>
          17.5 The Agreement is made for the benefit of the parties, and is not
          intended to benefit any third party or be enforceable by any third
          party. The rights of the parties to terminate, rescind, or agree any
          amendment, waiver, variation or settlement under or relating to the
          Agreement are not subject to the consent of any third party.
        </StyledParagraph>
        <StyledParagraph>17.6 Subject to Clause 10.1:</StyledParagraph>
        <StyledUl>
          <StyledLi>
            the Agreement and the acceptable use policy referred to in herein
            constitutes the entire agreement between the parties in relation to
            the subject matter of the Agreement, and supersedes all previous
            agreements, arrangements and understandings between the parties in
            respect of that subject matter; and
          </StyledLi>
          <StyledLi>
            neither party will have any remedy in respect of any
            misrepresentation (whether written or oral) made to it upon which it
            relied in entering into the Agreement.
          </StyledLi>
        </StyledUl>
        <StyledParagraph>
          17.7 The Agreement will be governed by and construed in accordance
          with the laws of Croatia; and the courts of Croatia will have
          exclusive jurisdiction to adjudicate any dispute arising under or in
          connection with the Agreement.
        </StyledParagraph>
        <StyledH3>18. Limitation of liability</StyledH3>
        <StyledParagraph>
          Notwithstanding any other provision in this document, Parklio Ltd.
          shall not be liable for any damages, losses, costs, or expenses,
          whether direct, indirect, incidental, special, consequential,
          exemplary or punitive, arising out of or in connection with:
        </StyledParagraph>
        <StyledUl>
          <StyledLi>
            Any damage, loss, injury, or other consequences resulting from the
            use or activation of the auto-close option, or the use of the
            Parklio Connect application to manipulate or interact with the
            product. The user acknowledges and agrees that the auto-close
            feature and the Parklio Connect application are provided &#34;as
            is&#34;, and that Parklio disclaims any and all warranties, either
            express or implied, regarding these features;
          </StyledLi>
          <StyledLi>
            Any damage, loss, injury, or other consequences arising out of a
            vehicle being parked over the barrier, irrespective of the
            circumstances leading to this action. It is the responsibility of
            the user to ensure the Parklio product is used appropriately and in
            accordance with the provided instructions and safety guidelines; or
          </StyledLi>
          <StyledLi>
            Any damage, loss, injury, or other consequences arising out of the
            product being hit by a vehicle, irrespective of whether this was a
            deliberate act or an accident.
          </StyledLi>
        </StyledUl>
        <StyledParagraph>
          This limitation of liability shall apply to the fullest extent
          permitted by law, regardless of the form or cause of action, whether
          in contract, tort (including negligence), strict liability or
          otherwise, even if Parklio has been advised of the possibility of such
          damages. By using the product, you, the user, agrees to indemnify and
          hold Parklio harmless from any claims resulting from any action taken
          by you that leads to such damages, losses, costs, or expenses.
        </StyledParagraph>
        <StyledH2>Schedule 1</StyledH2>
        <StyledH2>Service Level Agreement</StyledH2>
        <StyledH3>1. Introduction</StyledH3>
        <StyledParagraph>1.1 In this Schedule:</StyledParagraph>
        <StyledParagraph>
          <b>&#34;New Functionality&#34;</b> means new functionality that is
          introduced to the Platform by an Upgrade; and
        </StyledParagraph>
        <StyledParagraph>
          1.2 References in this Schedule to Paragraphs are to the paragraphs of
          this Schedule, unless otherwise stated.
        </StyledParagraph>
        <StyledH3>2. Helpdesk</StyledH3>
        <StyledParagraph>
          2.1 The Provider will make available, during Business Hours, a
          telephone and email helpdesk facility for the purposes of:
        </StyledParagraph>
        <StyledUl>
          <StyledLi>
            assisting the Customer with the configuration of the Platform and
            the integration of the Platform with the Customer&#39;s other
            systems;
          </StyledLi>
          <StyledLi>
            assisting the Customer with the proper use of the Platform; and/or
          </StyledLi>
          <StyledLi>
            determining the causes of errors and fixing errors in the Platform.
          </StyledLi>
        </StyledUl>
        <StyledParagraph>
          2.2 Subject to Paragraph 2.3, the Customer must make all requests for
          Support Services through the helpdesk, and all such requests must
          include sufficient information to allow the Provider to give the
          Customer a satisfactory resolution to its request.
        </StyledParagraph>
        <StyledParagraph>
          2.3 The Provider will use reasonable endeavours to ensure that a
          member of its support staff can be reached by mobile phone outside
          Business Hours in the case of an emergency.
        </StyledParagraph>
        <StyledH3>3. Response and resolution times</StyledH3>
        <StyledParagraph>3.1 The Provider will:</StyledParagraph>
        <StyledUl>
          <StyledLi>
            use all reasonable endeavours to respond to requests for Support
            Services made through the helpdesk; and
          </StyledLi>
          <StyledLi>
            use all reasonable endeavours to resolve issues raised by the
            Customer,
          </StyledLi>
        </StyledUl>
        <StyledParagraph>
          3.2 The Provider will determine, acting reasonably, in to which
          severity category an issue raised through the Support Services falls.
        </StyledParagraph>
        <StyledParagraph>
          3.3 All Support Services will be provided remotely unless expressly
          agreed otherwise by the Provider.
        </StyledParagraph>
        <StyledH3>4. Limits on Support Services</StyledH3>
        <StyledParagraph>
          4.1 Where the total person-hours spent by the Provider performing the
          Support Services under Paragraphs 2 and 3 during any calendar month
          exceed 10 hours, then:
        </StyledParagraph>
        <StyledUl>
          <StyledLi>
            the Provider will cease to have an obligation to provide those
            Support Services to the Customer during that period; providing that
          </StyledLi>
          <StyledLi>
            the Provider may agree to provide additional such Support Services
            to the Customer during that period, but the provision of such
            services will be subject to payment by the Customer of additional
            Charges at the Provider&#39;s standard hourly rates from time to
            time.
          </StyledLi>
        </StyledUl>
        <StyledParagraph>
          4.2 The Provider shall have no obligation under the Agreement to
          provide Support Services in respect of any fault or error caused by:
        </StyledParagraph>
        <StyledUl>
          <StyledLi>the improper use of the Platform; or</StyledLi>
          <StyledLi>
            the use of the Platform otherwise than in accordance with the
            Documentation.
          </StyledLi>
        </StyledUl>
        <StyledH3>5. Upgrades</StyledH3>
        <StyledParagraph>
          5.1 The Customer acknowledges that from time to time during the Term
          the Provider may apply Upgrades to the Platform, and that such
          Upgrades may, subject to Paragraph 5.2, result in changes the
          appearance and/or functionality of the Platform.
        </StyledParagraph>
        <StyledParagraph>
          5.2 No Upgrade shall disable, delete or significantly impair the
          Protected Functionality.
        </StyledParagraph>
        <StyledParagraph>
          5.3 The Provider will give to the Customer reasonable prior written
          notice of the application of any significant Upgrade to the Platform.
          Such notice shall include details of the specific changes to the
          functionality of the Platform resulting from the application of the
          Upgrade.
        </StyledParagraph>
        <StyledParagraph>
          5.4 The Customer shall not be subject to any additional Charges
          arising out of the application of the Upgrade, save where:
        </StyledParagraph>
        <StyledUl>
          <StyledLi>
            the Upgrade introduces New Functionality to the Platform;
          </StyledLi>
          <StyledLi>
            that New Functionality does not serve the same purpose as legacy
            functionality that ceases or has ceased to be available as a result
            of any Upgrade;
          </StyledLi>
          <StyledLi>
            access to or use of the New Functionality is chargeable to the
            customers of the Provider using the Platform generally; and
          </StyledLi>
          <StyledLi>
            any decision by the Customer not to pay the Charges for the New
            Functionality will not prejudice the Customer&#39;s access to and
            use of the rest of the Platform.
          </StyledLi>
        </StyledUl>
        <StyledH3>6. Uptime commitment</StyledH3>
        <StyledParagraph>
          6.1 The Provider shall use all reasonable endeavours to ensure that
          the Platform is available 99.9% of the time during each calendar
          month, subject to Paragraph 8.
        </StyledParagraph>
        <StyledH3>7. Back-up and restoration</StyledH3>
        <StyledParagraph>
          7.1 Subject to Paragraph 7.2, the Provider will:
        </StyledParagraph>
        <StyledUl>
          <StyledLi>
            make back-ups of the Customer Materials stored on the Platform on a
            daily basis, and will retain such back-ups for at least 10 days ;
            and
          </StyledLi>
          <StyledLi>
            at least once every day, the Provider will arrange for the off-site
            storage of a current back-up of the Customer Materials stored on the
            Platform (which will be over-written on the following off-site
            back-up date).
          </StyledLi>
        </StyledUl>
        <StyledParagraph>
          7.3 In the event of the loss of, or corruption of, Customer Materials
          stored on the Platform being notified by the Customer to the Provider
          under Paragraph 2, the Provider shall if so directed by the Customer
          use all reasonable endeavours] promptly to restore the Customer
          Materials from the most recent available backup copy.
        </StyledParagraph>
        <StyledH3>8. Scheduled maintenance</StyledH3>
        <StyledParagraph>
          8.1 The Provider may suspend access to the Platform in order to carry
          out scheduled maintenance, such maintenance to be carried out and such
          suspension to be for not more than 4 hours in each calendar month.
        </StyledParagraph>
        <StyledParagraph>
          8.2 The Provider must give to the Customer at least 14 days&#39;
          written notice of schedule maintenance, including full details of the
          expected Platform downtime.
        </StyledParagraph>
        <StyledParagraph>
          8.3 Platform downtime during scheduled maintenance carried out by the
          Provider in accordance with this Paragraph 8 shall not be counted as
          downtime for the purposes of Paragraph 6.
        </StyledParagraph>
        <StyledH2>Schedule 2</StyledH2>
        <StyledH2>Charges</StyledH2>
        <StyledH3>1. Introduction</StyledH3>
        <StyledParagraph>
          1.1 References in this Schedule to Paragraphs are to the paragraphs of
          this Schedule, unless otherwise stated.
        </StyledParagraph>
        <StyledParagraph>
          1.2 The Charges under the Agreement will consist of one time payment
          for usage of Parklio PMS platfrom
        </StyledParagraph>
        <StyledParagraph>
          1.3 No refund of charges will be made if the Agreement is terminated
        </StyledParagraph>
        <StyledH2>Schedule 3</StyledH2>
        <StyledH2>Acceptable Use Policy</StyledH2>
        <StyledH3>(1) This Policy</StyledH3>
        <StyledParagraph>
          This Acceptable Use Policy (the <b>&#34;Policy&#34;</b>) sets out the
          rules governing the use of our web services (the{' '}
          <b>&#34;Service&#34;</b>) and any content that you may submit to the
          Service (<b>&#34;Content&#34;</b>).
        </StyledParagraph>
        <StyledParagraph>
          By using the Service, you agree to the rules set out in this Policy.
        </StyledParagraph>
        <StyledH3>(2) General restrictions</StyledH3>
        <StyledParagraph>
          You must not use the Service in any way that causes, or may cause,
          damage to the Service or impairment of the availability or
          accessibility of the Service, or any of the areas of, or services on,
          the Service.
        </StyledParagraph>
        <StyledParagraph>You must not use the Service:</StyledParagraph>
        <ul>
          <StyledLi>
            in any way that is unlawful, illegal, fraudulent or harmful; or
          </StyledLi>
          <StyledLi>
            in connection with any unlawful, illegal, fraudulent or harmful
            purpose or activity
          </StyledLi>
        </ul>
        <StyledH3>(3) Reasonable Use</StyledH3>
        <StyledParagraph>
          You agree to use the Service in a reasonable way. If we determine that
          your use of the Service is not reasonable or that your use is causing
          degraded performance of the Service for you and for other Customers,
          we may impose limits on your use of the Service. Where possible we
          will endeavour to give you at least 24 hours prior notice that your
          usage is reduced before imposing any limits.
        </StyledParagraph>
        <StyledParagraph>
          Limits on your use of the Service may include, but are not limited to,
          the quantities and volumes of the following parameters:
        </StyledParagraph>
        <ul>
          <StyledLi>storage required to host and backup your data;</StyledLi>
          <StyledLi>sales transactions per calendar month;</StyledLi>
          <StyledLi>
            API (application programming interface) calls per 5 minute period;
          </StyledLi>
          <StyledLi>bandwidth usage per 24 hour period;</StyledLi>
          <StyledLi>
            locations, users, POS units, active SKU’s, customers.
          </StyledLi>
        </ul>
        <StyledH3>(4) Unlawful and illegal material</StyledH3>
        <StyledParagraph>
          You must not use the Service to store, host, copy, distribute,
          display, publish, transmit or send Content that is illegal or
          unlawful, or that will or may infringe a third party&#39;s legal
          rights, or that could give rise to legal action whether against you or
          us or a third party (in each case in any jurisdiction and under any
          applicable law).
        </StyledParagraph>
        <StyledParagraph>
          Content (and its publication on the Service) must not:
        </StyledParagraph>
        <ul>
          <StyledLi>be libellous or maliciously false;</StyledLi>
          <StyledLi>be obscene or indecent;</StyledLi>
          <StyledLi>
            infringe any copyright, moral rights, database rights, trade mark
            rights, design rights, rights in passing off, or other intellectual
            property rights;
          </StyledLi>
          <StyledLi>
            infringe any rights of confidence, rights of privacy, or rights
            under data protection legislation;
          </StyledLi>
          <StyledLi>
            constitute negligent advice or contain any negligent statement;
          </StyledLi>
          <StyledLi>constitute an incitement to commit a crime;</StyledLi>
          <StyledLi>
            be in contempt of any court, or in breach of any court order;
          </StyledLi>
          <StyledLi>
            be in breach of racial or religious hatred or discrimination
            legislation;
          </StyledLi>
          <StyledLi>be blasphemous;</StyledLi>
          <StyledLi>be in breach of official secrets legislation; or</StyledLi>
          <StyledLi>
            be in breach of any contractual obligation owed to any person.
          </StyledLi>
        </ul>
        <StyledParagraph>
          You must not submit any Content that is or has ever been the subject
          of any threatened or actual legal proceedings or other similar
          complaint.
        </StyledParagraph>
        <StyledH3>(5) Data mining</StyledH3>
        <StyledParagraph>
          You must not conduct any systematic or automated data collection
          activities (including without limitation scraping, data mining, data
          extraction and data harvesting) on or in relation to the Service
          without our express written consent.
        </StyledParagraph>
        <StyledH3>(6) Harmful software</StyledH3>
        <StyledParagraph>
          You must not use the Service to promote or distribute any viruses,
          Trojans, worms, root kits, spyware, adware or any other harmful
          software, programs, routines, applications or technologies.
        </StyledParagraph>
        <StyledParagraph>
          You must not use the Service to promote or distribute any software,
          programs, routines, applications or technologies that will or may
          negatively affect the performance of a computer or introduce
          significant security risks to a computer.
        </StyledParagraph>
        <StyledH2>Statement of Services</StyledH2>
        <StyledH4>Customer</StyledH4>
        <StyledParagraph>
          The Customer and its designated users are as named on the sales order.
        </StyledParagraph>
        <StyledH4>Customer notices</StyledH4>
        <StyledParagraph>
          The address and email address of the Customer are as stated on the
          sales order
        </StyledParagraph>
        <StyledH4>Platform specification</StyledH4>
        <StyledParagraph>
          Parklio Parking Management System(PMS)
        </StyledParagraph>
        <StyledParagraph>
          Parklio™ Parking Management System (PMS) enables reliable control and
          management of parking lots and Parklio™ smart parking products through
          a user-friendly interface.
        </StyledParagraph>
        <StyledH4>Licensing</StyledH4>
        <StyledParagraph>
          The features which are licensed to the Customer are as stated on the
          sales order
        </StyledParagraph>
        <StyledParagraph>
          The Customer may add features and extras at any time by completing a
          supplementary sales order.
        </StyledParagraph>
      </StyledDiv>
      <StyledLink to='/login'>{'< '}BACK</StyledLink>
    </Container>
  );
}

const StyledP = styled.p`
  font-family: 'Montserrat';
  font-size: 14px;
  text-align: justify;
  color: ${Color.TEXT_DARKER};
  margin-left: 40px;
`;

const StyledParagraph = styled.p`
  font-family: 'Montserrat';
  font-size: 14px;
  text-align: justify;
  color: ${Color.TEXT_DARKER};
  margin-left: 20px;
`;

const StyledText = styled.p`
  font-family: 'Montserrat';
  font-size: 14px;
  text-align: justify;
  color: ${Color.TEXT_DARKER};
`;

const StyledH2 = styled.h2`
  text-align: center;
  font-family: 'Montserrat';
  font-size: 24px;
`;

const StyledH3 = styled.h3`
  font-family: 'Montserrat';
  font-size: 16px;
`;

const StyledH4 = styled.h4`
  margin-left: 20px;
  font-family: 'Montserrat';
  font-size: 15px;
`;

const StyledUl = styled.ul`
  margin-left: 40px;
`;

const StyledLi = styled.li`
  font-family: 'Montserrat';
  color: ${Color.TEXT_DARKER};
  list-style-type: lower-alpha;
  padding-left: 10px;
  font-size: 14px;
`;

const StyledNestedLi = styled.li`
  font-family: 'Montserrat';
  color: ${Color.TEXT_DARKER};
  list-style-type: lower-roman;
  padding-left: 10px;
  font-size: 14px;
`;

const StyledDiv = styled.div`
  width: 60%;
  padding: 35px 30px;
  background-color: ${Color.BACKGROUND_LIGHTEST};
  border: 1px solid ${Color.BORDER_LIGHT};
  border-radius: 10px;
  margin: 100px 100px 50px 100px;
  display: block;
  min-height: 250px;
  text-align: justify;

  @media (max-width: 999px) {
    margin: 50px 100px 50px 100px;
    width: 90%;
  }
`;

const StyledLink = styled(Link)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 160%;
  color: ${Color.TEXT_LIGHTER};
  margin-bottom: 100px;
`;
