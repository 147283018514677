import React, { useMemo } from 'react';
import AppRole from '../../constants/Shared/AppRole';
import useAuthContext from '../../hooks/Auth/useAuthContext';
import useUserHasRole from '../../hooks/Shared/useUserHasRole';
import SideMenu from '../Shared/SideMenu';

interface AccountMenuPorps {
  close: () => void;
}

export default function AccountMenu(props: AccountMenuPorps) {
  const { close } = props;
  const userHasRole = useUserHasRole();
  const { sessionData } = useAuthContext();

  const userCanReadAccountInfo = useMemo(() => {
    const roles =
      AppRole.PARKLIO_INTERNAL_SUPER_ADMIN |
      AppRole.PARKLIO_INTERNAL_ADMIN |
      AppRole.PMS_SUPER_ADMIN;

    return userHasRole(roles);
  }, [userHasRole]);

  const userCanReadAccountLicenses = useMemo(() => {
    const roles =
      AppRole.PMS_SUPER_ADMIN | AppRole.PMS_ADMIN | AppRole.PMS_OPERATOR;

    return userHasRole(roles);
  }, [userHasRole]);

  const userCanReadAccountUsers = useMemo(() => {
    const roles =
      AppRole.PARKLIO_INTERNAL_SUPER_ADMIN |
      AppRole.PARKLIO_INTERNAL_ADMIN |
      AppRole.PMS_SUPER_ADMIN |
      AppRole.PMS_ADMIN;

    return userHasRole(roles);
  }, [userHasRole]);

  const userIsSuperAdmin = useMemo(() => {
    const roles =
      AppRole.PARKLIO_INTERNAL_SUPER_ADMIN | AppRole.PMS_SUPER_ADMIN;

    return userHasRole(roles);
  }, [userHasRole]);

  const userIsParklioInternal = useMemo(() => {
    const roles =
      AppRole.PARKLIO_INTERNAL_SUPER_ADMIN |
      AppRole.PARKLIO_INTERNAL_ADMIN |
      AppRole.PARKLIO_INTERNAL_OPERATOR;
    return userHasRole(roles);
  }, [userHasRole]);

  return (
    <SideMenu
      close={close}
      title='Account Settings'
      subtitle={sessionData?.email}
    >
      <SideMenu.Navigation>
        {userCanReadAccountInfo && (
          <SideMenu.Link to={`/settings/account/${sessionData?.id}/info`}>
            Info
          </SideMenu.Link>
        )}
        {userCanReadAccountLicenses && (
          <SideMenu.Link to={`/settings/account/${sessionData?.id}/licenses`}>
            Licenses
          </SideMenu.Link>
        )}
        {userIsSuperAdmin && (
          <SideMenu.Link to={`/settings/account/${sessionData?.id}/invoice`}>
            Invoice Setup
          </SideMenu.Link>
        )}
        {userCanReadAccountUsers && (
          <SideMenu.Link to={`/settings/account/${sessionData?.id}/users`}>
            Parking Managers
          </SideMenu.Link>
        )}
        {userIsParklioInternal && (
          <SideMenu.Link
            to={`/settings/account/${sessionData?.id}/anpr-subscription`}
          >
            ANPR Image Storage
          </SideMenu.Link>
        )}
        {userIsParklioInternal && (
          <SideMenu.Link to={`/settings/account/${sessionData?.id}/detect`}>
            Detection
          </SideMenu.Link>
        )}
      </SideMenu.Navigation>
    </SideMenu>
  );
}
